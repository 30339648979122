import React, { Component } from "react";

class AmazonDesc extends Component {
  state = {
    text: "Actively participated in front-end & backend development for FBA modules"
  };

  render() {
    return this.state.text;
  }
}

export default AmazonDesc;