
import React, {Component} from 'react';



class Details extends  Component{

    state = {
        text: ` This project is based on mathematical model that calculate the dynamics pricing for electricity to customers, This project is based on coalition be- tween different grids and it is trying to satisfying the Ness equilibrium. Ex- pansion part of project deals with communicate part between grid to grid and grid to users.`
      };
    
      render() {
        return this.state.text;
      }
      
}


export default Details;










