import React, { Component } from "react";

class AindraSmartAttendanceSystemTitle extends Component {
  state = {
    text: "Smart-Attendance System"
  };

  render() {
    return this.state.text;
  }
}

export default AindraSmartAttendanceSystemTitle;