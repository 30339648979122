import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../actions/Actions";
import {connect} from "react-redux";
import iitkgp from './../../img/iitkgp.png';
import ieee from './../../img/ieee.png';
import jnv from './../../img/jnv.png';
import mitac from './../../img/mitac.png';
import quectel from './../../img/quectel.png';

import axios from "axios";



class Description extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{ width:'100%', background:'#FDEBD0'}}>
               <div style={{ display:'flex', justifyContent:"center", paddingTop:"10px"}}>
                    <div style={{ width:"90%",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", fontFamily: "serif", fontSize:"100%", fontStyle:"bold"}}>
                        <p style={{margin:"0px"}}>Gradudated in </p>
                        <p style={{ margin: 0, padding:0, }}>
                            Computer Science & Engineering 
                        </p>
                        <p>from IIT Khargpur</p>
                        <img style={{height:"40px", width:"40px", marginTop:"2px"}} src={iitkgp}></img>
                    </div>
                   
               </div>
               <div style={{ display:'flex', marginTop:"5px", justifyContent:"center"  }}>
                    <div style={{width:'27%', height:'2px', background: 'black'}}></div>
               </div>

               <div style={{ display:'flex',alignItems:"center", justifyContent:"center", marginBottom:"20px",fontFamily: "serif", fontSize:"100%" }}>
                    <div style={{ width:"90%",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{marginTop:20, marginBottom:"0px"}}>Research paper</p>
                        <p style={{ marginBottom: 0, padding:0, textAlign:"center"}}>
                            DEMANDS: Distributed Energy Management Using Non-Cooperative Scheduling in Smart Grid.
                        </p>
                        <div style={{margin:"0px"}}>
                             <p>Journal : IEEE System Journal Manuscript</p>
                        </div>
                        <div style={{margin:"-15px"}}>
                             <p>ID: ISJ-RE-16-04946.R2</p>
                        </div>
                        <div style={{margin:"0px"}}>
                            <img style={{height:"50px", width:"50px",marginTop:"0px"}} src={ieee}></img>
                        </div>
                    </div>
               </div>

               <div style={{ display:'flex',alignItems:"center", justifyContent:"center", marginBottom:"20px",fontFamily: "serif", fontSize:"100%" }}>
                    <div style={{ width:"90%",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{marginTop:20, marginBottom:"0px"}}>Visited Quectel Shanghai, China </p>
                        <div style={{margin:"0px"}}>
                             <p>As Technologist & Scientist</p>
                        </div>
                        <p style={{ marginTop: -20, marginBottom: 0, padding:0, textAlign:"center"}}>
                            Analysis:  Autograde/Industrial processor for Automobile
                        </p>
    
                        <div style={{margin:"0px"}}>
                            <img style={{height:"50px", width:"50px",marginTop:"0px", margin:"3px"}} src={quectel}></img>
                        </div>
                    </div>
               </div>

               <div style={{ display:'flex',alignItems:"center", justifyContent:"center", marginBottom:"20px",fontFamily: "serif", fontSize:"100%" }}>
                    <div style={{ width:"90%",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <p style={{marginTop:20, marginBottom:"0px"}}>Visited MiTac Jiangsu, China & Taiwan</p>
                        <div style={{margin:"0px"}}>
                             <p>As Technologist & Scientist</p>
                        </div>
                        <p style={{ marginTop: -20, textAlign:"center"}}>
                            Analysis:  Automatic PCB mouting & SOC components
                        </p>
                        <div style={{margin:"0px"}}>
                            <img style={{height:"50px", width:"50px",marginTop:"0px", margin:"3px"}} src={mitac}></img>
                        </div>
                    </div>
               </div>

               <div style={{ display:'flex', marginTop:"5px", justifyContent:"center"  }}>
                    <div style={{width:'27%', height:'2px', background: 'black'}}></div>
               </div>
               <div style={{ marginTop:12, display:'flex', justifyContent:"center"  }}>
                    <div style={{ width:"90%",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", fontFamily: "serif", fontSize:"100%", fontStyle:"bold"}}>
                        <p style={{margin:"0px"}}>Basic Education in </p>
                        <p style={{ margin: 0, padding:0, }}>
                            Maths, Physics, Chemistry, Biology
                        </p>
                        <p style={{ marginBottom: 0, padding:0}}>
                            from Jawahar Navodaya Vidyalaya 
                        </p>
                        <p>Gyanpur, Bhadohi</p>
                        <img style={{height:"60px", width:"60px", marginTop:"2px", marginBottom:"15px"}} src={jnv}></img>
                    </div>
                   
               </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(Description);