import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateCentralContent} from "../../actions/Actions";
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Description from '../Description';
import KnowledgeDetails from '../KnowledgeDetails';
import ExperienceDetails from '../ExperienceDetails';
import IndustrialProjects from '../IndustrialProjects';
import InitiationDetails from '../InitiationDetails';

class Home extends  Component{

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = props;
       
    }

    componentDidMount=()=>{
        const { cookies } = this.props;
        let  centralContent = {
            type:"CENTRAL_CONTENT",
            stream: "Basic Education",
            std: 4
        }
        let  centralContentUser = {
            type:"USER_INFO",
            stream: "Basic Education",
            std: 4
        }
        //this.props.updateCentralContent(( cookies.get('f4e_auth') != null ) ? centralContentUser: centralContent);
    }

    scrollUp=()=>{
        window.scroll(0,150);
        return true;
    }

    render() {
    
        return (
            <div style={{margin:0, padding:0, width: "80%", position: "absolute",left: "10%"}}>

                <div style={{width:'100%',  margin: 0, padding:0}}>
                    <Header/>
                </div>

                <div style={{width:'100%', margin: 0, padding:0}}>
                   <Description/>
                </div>

                <div style={{width:'100%', marginTop:"12px", padding:0}}>
                    <KnowledgeDetails/>
                </div>

                <div style={{width:'100%', marginTop:"10px", padding:0}}>
                   <ExperienceDetails/>
                </div>

                <div style={{width:'100%', marginTop:"10px", padding:0}}>
                   <IndustrialProjects/>
                </div>

                <div style={{width:'100%', marginTop:"10px", padding:0}}>
                   <InitiationDetails/>
                </div>
               
                <div style={{width:'100%', marginTop:"10px", padding:0}}>
                    <Footer/>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
      centralContent: state.deskReducer.centralContent,
})

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Home));