
import React, {Component} from 'react';

class Location extends  Component{

    state = {
        text: `Work at: Pursuing Engineering`
      };
    
      render() {
        return this.state.text;
      }
      
}

//Work at: Hi-Tek Electronics

export default Location;
