import React, { Component } from "react";

class OlaWorkYear extends Component {
  state = {
    text: "March-2021 to April 2023"
  };

  render() {
    return this.state.text;
  }
}

export default OlaWorkYear;