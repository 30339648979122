import React, { Component } from "react";

class MMTMyBizTitle extends Component {
  state = {
    text: `MyBiz : For the corporate users`
  };

  render() {
    return this.state.text;
  }
}

export default MMTMyBizTitle;