

import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../../../actions/Actions";
import {connect} from "react-redux";

import EicherHMIClusterDesc from '../../../../text/expproject/eicher/EicherHMIClusterDesc';
import EicherHMIClusterTitle from '../../../../text/expproject/eicher/EicherHMIClusterTitle';



class HmiCluster extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div>
                

            <div style={{fontFamily: "Sans-serif", fontSize:"90%", fontStyle:"italic", textAlign:"justify", fontWeight:"regular"}}>
                <div>
                    <h5 style={{marginTop: "10px", fontSize:13}}><EicherHMIClusterTitle/></h5> <br/>
                </div>
                <div style={{border:"1px dotted gray", marginTop:"-20px", borderRadius:"9px", boxShadow:"inset 0 10px 10px -10px"}}>
                    <p style={{margin: "20px", fontSize:13}}>
                        <EicherHMIClusterDesc/>
                    </p>
                </div>
            </div>
</div>

            
               
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(HmiCluster);







