import React, { Component } from "react";

class OlaProfileTile extends Component {
  state = {
    text: `Profile based features on electric vehicle    `
  };

  render() {
    return this.state.text;
  }
}

export default OlaProfileTile;