import React, { Component } from "react";

class EicherReOSTitle extends Component {
  state = {
    text: `ReOS: Indigenous developed 2W Operating System`
  };

  render() {
    return this.state.text;
  }
}

export default EicherReOSTitle;