import React, { Component } from "react";

class OlaDriverDocsTitle extends Component {
  state = {
    text: `Driver Docs: facilitate users to see their Docs on Vehicle
    `
  };

  render() {
    return this.state.text;
  }
}

export default OlaDriverDocsTitle;