import React, { Component } from "react";

class MMTMyBizDesc extends Component {
  state = {
    text: `Managing the corporate team and gives the technical direction involves establishing a technical vision, 
    resolving technical disagreements and provides software deliverables at time. I use 80% of my times for actively 
    doing coding/designing LLD,HLD which is very crucial and essential in nature and remain 20% of times I use for 
    management’s work. We Initiated and completed REM service in MyBiz by using Java, Spring, Kafka and mysql etc. 
    technologies for reporting and managing the expanses for corporate where admin can see their monthly, annually report. 
    We also initiated new landing service for corporate so that user can resister with MyBiz of MMT via GSTN and Company’s 
    document and verify their account to get the corporate benefit without manual intervention. The new landing service is 
    one of the sub-service that uses internally CCB, CUS services, which is developed in Java (SpringBoot) by usiing Kafka, Zookeeper, Activity.`
  };

  render() {
    return this.state.text;
  }
}

export default MMTMyBizDesc;