import React, { Component } from "react";

class EicherHMIClusterDesc extends Component {
  state = {
    text: `ReMirror is the application used to show the vehicle parameters in the HMI Cluster Unit Display.

Currently this application is showing the major parameters in the live vehicle display like Speed, SOC, Battery temperature, IMD Status,Riding Mode, Odometer, Side Indicator and Hazards.

This application also responds based on the user touching the screen and performing operations like animations and calculations done on this application. In our upcoming phase, the user can also send data to the vehicles to perform any operations like vehicle mode, etc.,
`
  };

  render() {
    return this.state.text;
  }
}

export default EicherHMIClusterDesc;