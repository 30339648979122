import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../../../actions/Actions";
import {connect} from "react-redux";
import iitkgp from './../../../../img/iitkgp.png';
import ieee from './../../../../img/ieee.png';


import axios from "axios";
import Title from '../../../../text/indproject/smartgrid/Title';
import Location from '../../../../text/indproject/smartgrid/Location';
import Detail  from '../../../../text/indproject/smartgrid/Detail';
import smartgridIcon from './../../../../img/smartgrid.png';


class SmartGrid extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
          
            <div style={{  display:'flex', justifyContent: 'center', alignItems:'center', background:"#FDEBD0", padding: '10px'}}>
                <div style={{width:"75%",marginLeft:"10px",   fontSize:13}}>
                    <Detail/>
                </div>
                <div style={{ border:'1px dotted gray',width:"25%",  borderRadius:'5px', marginLeft:'30px', padding: '10px', display:"flex", flexDirection:"column", justifyContent: 'center', alignItems:'center', boxShadow:"inset 0 10px 30px -10px"}}>
                    <div style={{width: "100%"}}>
                        <img src={smartgridIcon} style={{height: "50px", width:"60px", marginLeft:"5px"}} ></img>
                    </div>
                    <div style={{width: "100%", height:"100%", display:"flex", flexDirection:"column"}}>
                        <div style={{height:"50%", width:"100%"}}> </div>
                        <div style={{height:"50%", width:"100%", marginLeft:"8px", marginTop:"8px"}}>
                            <p style={{ margin:"0px", fontSize:"10px", fontSize:"9px"}}><Title/></p>
                            <p style={{margin:"0px", fontSize:"10px",  fontSize:"9px", marginTop:"8px"}}><Location/></p>
                        </div>
                    </div>
                  
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(SmartGrid);