import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "./../../desk/actions/Actions";
import {connect} from "react-redux";
import iitkgp from './../../img/iitkgp.png';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";

import SmartATM from './industries/smartatm/SmartATM';
import SolarControl from './industries/solarcontrol/SolarControl';
import SineWave from './industries/sinewave/SineWave';
import TheifDetector from './industries/theifdetector/TheifDetector';
import PowerFactor from './industries/poerfactor/PowerFactor';
import SmartGrid from './industries/smartgrid/SmartGrid';




class IndustrialProjects extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{ width:'100%', background:'white'}}>
                
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center", background:'#5D6D7E', fontFamily: "serif", fontSize:"100%", fontWeight:"bold", color:"white"}}>
                        <div style={{marginLeft:"15px",  marginTop:"5px", fontFamily: "serif", fontSize:"100%", fontWeight:"bold", color:"white"}}>
                            <p>Industrial Project</p>
                        </div>
                </div>

                <SmartATM/>
                <div style={{ marginTop: '5px'}}></div>
                <SolarControl/>
                <div style={{ marginTop: '5px'}}></div>
                <SmartGrid/>
                <div style={{ marginTop: '5px'}}></div>
                <SineWave/>
                <div style={{ marginTop: '5px'}}></div>
                <PowerFactor/>
                <div style={{ marginTop: '5px'}}></div>
                <TheifDetector/>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(IndustrialProjects);