import React, { Component } from "react";

class MMTWorkYear extends Component {
  state = {
    text: `March-2018 to Sept 2020`
  };

  render() {
    return this.state.text;
  }
}

export default MMTWorkYear;