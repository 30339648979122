import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "./../../desk/actions/Actions";
import {connect} from "react-redux";
import iitkgp from './../../img/iitkgp.png';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";

import fight4eduIcon from './../../img/fight4edu.png';
import sfpIcon from './../../img/sfp.png';
import aazadbharatIcon from './../../img/logo.png';





class InitiationDetails extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{fontFamily: "Sans-serif" }}>
                <div style={{ display: 'flex',flexDirection:"column", justifyContent: "space-around", alignItems:"center", background:'#AED6F1'}}>
                    <div style={{ height:"30px", marginTop:"50px", display: 'flex', flexDirection:"column",alignItems:"center", justifyContent: "center" }}>
                        <h4 style={{fontSize:"120%", fontFamily: "Sans-serif"}}>Initiation</h4>
                        <p style={{textAlign:"center", width:"80%",fontFamily: "Sans-serif", fontSize:"70%", fontStyle:"italic"}}>I, Lakshmi S. Patel started three organizations that lead to social enhancement, education improvement, 
                            technology versatility. <br/>
                            Objective of Fight For Education is to provide quality education and research to learner irrespective of their economical status. 
                            Main focus of Aazaad Bharat is to build brotherhood and nationalist temprament, along that fight against corruption so that 
                            Satya Mev Jayete will be alway true.  
                            Area of works of Sure For Pure is to enhancing/utilizing the science/technologies to ease the works and let India lead in technologies.


                        </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{margin: "50px", display: 'flex', flexDirection:"column",alignItems:"center", justifyContent: "center" }}>
                            <img src={fight4eduIcon} style={{width:"75px", height:"100px"}} />
                            
                            <div style={{marginTop: "30px"}}>
                                <h6>Fight For Education Pvt Ltd</h6>
                            </div>
                            <div>
                                <h6>office@fight4edu.com</h6>
                            </div>
                            <div>
                                <h6>fight4edu.com | fightforedu.com</h6>
                            </div>
                        </div>
                        <div style={{margin: "50px", display: 'flex', flexDirection:"column",alignItems:"center", justifyContent: "center" }}>
                            <img src={aazadbharatIcon} style={{width:"100px", height:"100px"}} />
                          
                            <div style={{marginTop: "30px"}}>
                                <h6>Aazaad Bharat</h6>
                            </div>
                            <div>
                                <h6>aazaadbharat.com</h6>
                            </div>
                           
                        </div>
                        <div style={{margin: "50px", display: 'flex', flexDirection:"column",alignItems:"center", justifyContent: "center" }}>
                            <img src={sfpIcon} style={{width:"100px", height:"100px"}} />
                           
                            <div style={{marginTop: "30px"}}>
                                <h6>Sure For Pure Pvt Ltd</h6>
                            </div>
                            <div>
                                <h6>office@sureforpure.com</h6>
                            </div>
                            <div>
                                <h6>sureforpure.com</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(InitiationDetails);