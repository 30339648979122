import React, { Component } from "react";

class HiTekPFCTitle extends Component {
  state = {
    text: `PFC: Power Factor Correction Control Unit `
  };

  render() {
    return this.state.text;
  }
}

export default HiTekPFCTitle;