import React, { Component } from "react";

class AindraSmartAttendanceSystemDesc extends Component {
  state = {
    text: `Part of Smart-attendance core team,which is responsible for developing the backend 
    services designed/developed in Java by using Spring, Hibernate, RabitMQ, which help other 
    systems/services to provides essential functionality to fulfil the smart-attendance front 
    ser- vices. We use Java as language for coding, spring as Framework, Mongo DB for DB and 
    many more services and tools.`
  };

  render() {
    return this.state.text;
  }
}

export default AindraSmartAttendanceSystemDesc;