import React, { Component } from "react";

class OlaDesc extends Component {
  state = {
    text: `Part of technology development department - OLA-Electic Mobility Managing the OEM-Backend-team, owning the core services.`
  }

  render() {
    return this.state.text;
  }
}

export default OlaDesc;