import React, { Component } from "react";

class HiTekTrackingHiLineTitle extends Component {
  state = {
    text: `Tracking-Hi-Line`
  };

  render() {
    return this.state.text;
  }
}

export default HiTekTrackingHiLineTitle;