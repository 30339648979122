import React, { Component } from "react";

class AindraWorkYear extends Component {
  state = {
    text: "Sept 2014 to Sept 2015"
  };

  render() {
    return this.state.text;
  }
}

export default AindraWorkYear;