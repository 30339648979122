import React, { Component } from "react";

class OlaDataPipelineDesc extends Component {
  state = {
    text: `IOTs communication is always challenging in any system in term of efficiency and reliability. Vehicles ( Ola-Electric Scooter ) send million of datas to cloud with different informations. Saving the data for future, transforming and analysing of stream of millions of records with right programming tools is ours objective/mission. We have designed our system in such a ways that without any delay users send the command to vehicle,see the real time vehicle precise information on vehicl display. Our Datapipe line consistence of MQTT ( EMQ-X Enterprises ), Kafka and different Flink services. For future uses, we keep data in blob-storage via Hive, MSTR query processing engine. We have efficienct data line path for cloud to vehicle and vehicle to cloud communication.

    `
  };

  render() {
    return this.state.text;
  }
}

export default OlaDataPipelineDesc;