import React, { Component } from "react";

class EicherImuBluetoothMagnatoTitle extends Component {
  state = {
    text: `ReSid: A signals analysis tool`
  };

  render() {
    return this.state.text;
  }
}

export default EicherImuBluetoothMagnatoTitle;

