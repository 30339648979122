import React, { Component } from "react";

class AmazonFBATitle extends Component {
  state = {
    text: "FBA Subscribe and Save"
  };

  render() {
    return this.state.text;
  }
}

export default AmazonFBATitle;