import React, { Component } from "react";

class AindraPos extends Component {
  state = {
    text: "Software Engineer-I"
  };

  render() {
    return this.state.text;
  }
}

export default AindraPos;