import React, {Component, useEffect} from 'react';
import {updateCentralContent, updateStdClass}  from "../../../desk/actions/Actions";
import {connect} from "react-redux";
import { Row, Col } from 'react-bootstrap';

import axios from "axios";

import { USER_INFO, CENTRAL_CONTENT} from './../../constants/Constants';
import {Form, Button, Badge} from "react-bootstrap";
import $ from "jquery";
import * as ApiUrl from "../../../api-url/ApiUrl";
import { updateF4EAuth, updateLoginInfo} from "../../actions/Actions";
import { instanceOf } from "prop-types";


import lspatelResume from "./../../../../src/img/resume/cv.pdf";

import './LoginPage.css';

import lspatelIcon from "./../../../../src/img/personnel/ceo.png";
import lspatelBgrIcon from "./../../../../src/img/personnel/lspatel_bgr.png";
import gitIcon from './../../../../src/img/git.png';
import linkdinIcon from './../../../../src/img/linkdin.png';
import cvIcon from './../../../../src/img/resume.png'; 
import CloseButton from "../../../img/home/closebutton.png";



class Header extends  Component{



    static propTypes = {
        cookies: null
    };

    constructor(props) {
        super(props);

        const { cookies } = props;
        this.state ={
            f4e_auth:  null,
            showLoginButton: true,
            showLoginPage: false,
            loginInfo: { errorStatus:false, msg:""},
            signupInfo: { errorStatus:false, msg:""},
            togglingDetail:false,
            isLoading: false,
            signupInfo:{},
            borderWidth:0,
            loginMessage:"Let him know, the purpose",
            resume: lspatelResume,
            loginMessageColor:"green"
        }
    }


    componentDidMount() {
      

    }

    doActionOnLoginAndLogout=()=>{
        if( this.state.showLoginButton == true ){
            this.setState({showLoginPage: true});
        }else{
            
            this.setState({showLoginButton: true});
            this.props.updateLoginInfo({loginStatus:false});
        }
    }

    showLoginPage=()=>{
       
        this.setState({
            showLoginPage: true
        });
    }

    hideLoginAndLogoutPage=()=>{
        this.setState({showLoginPage: false,loginInfo: {errorStatus:false, msg:""}, loginMessageColor:"green", loginMessage:"Let him know, the purpose",});
    }

    hideOutLoginFailedMessage=()=>{
        this.setState({loginInfo: {status:false, msg:""}});
    }

    getSymbol=(value)=>{
        value = value %10;
       switch(value){
        case 1: return 'A';
        case 2: return 'B';
        case 3: return 'C';
        case 4: return 'D';
        case 5: return 'E';
        case 6: return 'F';
        case 7: return 'G';
        case 8: return 'H';
        case 9: return 'I';
       }
    }

    
    getDoubleDigit=(value)=>{
            switch(value){
                case 0: return '00';
                case 1: return '01';
                case 2: return '02';
                case 3: return '03';
                case 4: return '04';
                case 5: return '05';
                case 6: return '06';
                case 7: return '07';
                case 8: return '08';
                case 9: return '09';
                default: return value;
            }
    }

    onSubmitTheForm=()=>{
       
        
        let password = document.getElementById("password").value;
        var currentdate = new Date(); 
        var passkey =  ""+ this.getDoubleDigit(currentdate.getMonth())+""+this.getDoubleDigit(currentdate.getDate())+""+this.getDoubleDigit(currentdate.getHours());
        var passkeyPlusOne = ""+ this.getDoubleDigit(currentdate.getMonth())+""+this.getDoubleDigit(currentdate.getDate())+""+this.getDoubleDigit(currentdate.getHours()+1);
        console.log("passkey"+passkey);
        
       
        var cvButton = document.getElementById("cvID");

        if( password === passkey  || password === passkeyPlusOne ){
            console.log("Password Matched");
            this.setState({showLoginPage: false });
            cvButton.click();
            
        }else {
            console.log("Password did not Matched");
            this.setState({showLoginPage: true, resume: null, borderWidth: 1, loginMessage: "Don't guess! Please ask him", loginMessageColor:"red"});
        }
    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (

            
            <div>
                
{
     this.state.showLoginPage == true &&
     <div id="login-page" style={{zIndex:100, position:"fixed" , }}>
         <div id="central-login-form"  style={{ top: (window.innerHeight-230)/2, left: (window.innerWidth-350)/2, width: 350, height: 230}}>
             <div style={{float:"right", marginRight:0}} onClick={()=>this.hideLoginAndLogoutPage()}>
                 <img style={{ width: 20, height: 20}}
                      src={CloseButton}
                      alt="Image One"
                 />
             </div>
             <div style={{ clear:"both"}}></div>
             <div id="login-form-div"  style={{marginLeft:40, marginTop: 5, borderRadius: 12, borderWidth:5, borderColor:"black" ,width:250, height: 200}}>
                 <Form>
                     <Form.Group className="mb-3" controlId="formBasicPassword">
                         <Form.Control id="password" type="password" placeholder="Password" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBasicPassword">
                        <p style={{fontSize:10, textAlign: "center"}}>Please contact Mr. Patel for PASSCODE <br></br> 9044448020 or  er.lspatel@gmail.com</p>
                     </Form.Group>
                     <div style={{ borderRadius:"5px", borderStyle:"dotted", borderWidth: this.state.borderWidth, height:"30px", width:"100%", alignContent:"center", alignItems:"center"}}>
                            <p style={{margin:"4px", textAlign: "center", color: this.state.loginMessageColor}}>{this.state.loginMessage}</p>
                     </div>
                     <Button variant="primary" onClick={()=>this.onSubmitTheForm()} style={{marginTop: "20px"}}>
                         Submit
                     </Button>
                 </Form>
             </div>
         </div>
     </div>
}

{
            <div style={{ width:'100%', height:'200px', background:'DarkSlateGray'}}>
               <div style={{ display:'flex', justifyContent:"center"  }}>
                    <div style={{ height: "30px", marginLeft: "40%", background:"#ECF0F1", borderBottomLeftRadius:10,borderBottomRightRadius:10}}>
                        <p style={{marginLeft:10, marginRight: 10, marginTop:3, marginBottom: 0, padding:0, fontFamily:"serif", fontSize:"90%", fontStyle:"italic"}}>I belive in - Training of mind to think</p>
                    </div>
               </div>
               <div style={{ display:'flex', justifyContent:"space-around"}}>
                    <div style={{marginTop:-20, marginLeft:20}}>
                        <div style={{ display:'flex', justifyContent:"left"}}>
                            <div style={{ height: "180px", width:"150px"}}>
                                <img src={lspatelBgrIcon} style={{height: "180px", width:"150px",borderRadius:"10px"}}  />
                            </div>
                            <div style={{ height: "180px", width:"300px"}}>

                               <div style={{ display:'flex', flexDirection:"column", justifyContent:"space-around" }}>
                                    <div style={{height: "90px"}}>
                                     
                                    </div>
                                    <div style={{marginLeft: 20, fontFamily:"serif", fontSize:"100%"}}>
                                        <div style={{marginTop: "70px"}}>
                                            <h2 style={{ marginTop: "-35px", color:"white"}}>Lakshmi S. Patel</h2>
                                            <h5 style={{marginLeft:"67px", marginTop: "-15px", color:"white", marginLeft: "0px", fontSize: 18}}> Soft Technologist & Architect</h5>
                                       </div>
                                    </div>
                               </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop:"90px", marginLeft:"20%", borderRadius:"10px"}}>
                        <div style={{ display:"flex", justifyContent:"left"}} >
                            <a href={ this.state.resume}  download="resume_lakshmipatel" target='_blank'>
                                <div id="cvID"></div>
                            </a>
                            <img  src={cvIcon} style={{width:"80px", height:"80px"}} onClick={()=>this.showLoginPage()} />
                        </div>
                    </div>
                    

                    <div style={{ height: "100px", width:"150px",background:"black", marginTop:"60px", borderRadius:"15px", background:"white"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent: "space-between", alignItems:"center", marginTop: "2px" }}>
                            <div style={{display:"flex", justifyContent:"center", marginBottom:"10px", marginTop:"8px"}} >
                                <a href="https://github.com/patellakshmi" target="_blank" rel="noreferrer" >
                                    <img src={gitIcon} style={{height:"30px", width:"30px"}} />
                                </a>
                                <a href="https://www.linkedin.com/in/lakshmi-s-patel-87175277/" target="_blank" rel="noreferrer">
                                    <img src={linkdinIcon} style={{height:"35px", width:"35px", marginLeft:"10px"}} />
                                </a>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginBottom:"10px",fontFamily:"serif", fontSize:"90%", fontStyle:"italic"}}>
                                <p style={{margin:"0px", padding:"0px", fontSize:"85%"}}>+919044448020</p>
                                <p style={{margin:"0px", padding:"0px", fontSize:"85%"}}>er.lspatel@gmail.com</p>
                            </div>
                        </div>
                    </div>
               </div>
            </div>

    }

            </div>


        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(Header);