import React, { Component } from "react";

class EicherHMIClusterTitle extends Component {
  state = {
    text: `Data-Aggregator : A telemetric gatway`
  };

  render() {
    return this.state.text;
  }
}

export default EicherHMIClusterTitle;