
import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "./../../../actions/Actions";
import {connect} from "react-redux";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import amazonIcon from './../../../../img/amazon.png';
import AmazonPos from '../../../../text/expproject/amazon/AmazonPos';
import AmazonWrokYear from '../../../../text/expproject/amazon/AmazonWorkYear';





class PositionWorkYears extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{width:"20%", height:"100%", background:"#5D6D7E"}} >
                <div  style={{ display: 'flex', flexDirection:"column", justifyContent: "flex-start", alignItems:"center", padding: "15px"}}>
                    <img src={amazonIcon} style={{height: "90px", width:"100px"}} />
                    <div style={{ fontFamily: "serif", fontSize:"100%", fontWeight:"bold", color:"white"}}>
                        <p><AmazonPos/></p>
                    </div>
                    <div style={{marginTop: "-15px", height:"2px", width: "100px", background:"white"}}></div>
                    <div style={{ marginTop: "5px", fontFamily: "serif", fontSize:"70%", fontWeight:"bold", color:"white"}}>
                        <p><AmazonWrokYear/></p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(PositionWorkYears);




