
import React, {Component} from 'react';



class Details extends  Component{

    state = {
        text: `During persuing the M.Tech degree, I have developed the smart ATM machine that has capability to help the blind person to draw the money.
        Machine has capabiities to recognize the face and voice of person and take it as identification of user, after that machine prompt a serise of questions,
        after getting the all the asnwers correct, It dispense the money to user. 
        H/W parts - Camera, Audio, Speaker, Teller Machine, Dispense Machine. 
        S/W - C/C++ and Open CV and Matlab.
        `
      };
    
      render() {
        return this.state.text;
      }
      
}


export default Details;










