import React, { Component } from "react";

class EicherImuBluetoothMagnatoDesc extends Component {
  state = {
    text: `ReSid is the Royal Enfield inhouse application developed by ReOS Team for the Electric Vehicles. This application is used for testing and validating the included hardware parts and its functionality of the electric bikes. 

This application includes the testing features of hardwares like LTE - Network, Bluetooth, Wifi, GPS, Sim Details, Compass, Gyroscope and other details. This data is collected from the device and shows the values in graphical and text based format to the users. 

By using this application, the hardware team calculates and validates the required vehicle data to make the perfect product.`
  };

  render() {
    return this.state.text;
  }
}

export default EicherImuBluetoothMagnatoDesc;

