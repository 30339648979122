import React, { Component } from "react";

class MMTPos extends Component {
  state = {
    text: `Lead Software Engineer`
  };

  render() {
    return this.state.text;
  }
}

export default MMTPos;