import React, { Component } from "react";

class HiTekDesc extends Component {
  state = {
    text:`System programming & Backend development with products innovation, managing & supervising team `
  };

  render() {
    return this.state.text;
  }
}

export default HiTekDesc;