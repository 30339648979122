import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../actions/Actions";
import {connect} from "react-redux";
import iitkgp from './../../img/iitkgp.png';
import springIcon from './../../img/spring-boot.png';
import cppIcon from './../../img/cpp.png';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


import axios from "axios";

const cardColor ="#FDEBD0";


class KnowledgeUnit extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <span style={{height:"25px", margin:"2px"}}>
            <Badge >
                <div style={{ backgroundImage:`url(${this.props.icon})`, height:"30px", width: "56px", backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" }}>
                </div>
                <p style={{color:"black", marginTop:"5px", fontFamily:"monospace"}}>{this.props.name}</p>
            </Badge>
            </span>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(KnowledgeUnit);