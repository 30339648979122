import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../actions/Actions";
import {connect} from "react-redux";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import iitkgp from './../../img/iitkgp.png';
import springIcon from './../../img/spring-boot.png';
import javaIcon from './../../img/java.png';
import pythonIcon from './../../img/python.png';
import cppIcon from './../../img/cpp.png';
import shellIcon from './../../img/shell.png';
import perlIcon from './../../img/perl.png';
import awkIcon from './../../img/awk.png';
import assemblyIcon from './../../img/assembly.png';
import jsIcon from './../../img/js.png';
import reactjsIcon from './../../img/reactjs.png';
import scalaIcon from './../../img/scala.png';


import hibernateIcon from './../../img/hibernate.png';
import zookeeperIcon from './../../img/zookeeper.png';
import mqttIcon from './../../img/mqtt.png';
import flinkIcon from './../../img/flink.png';
import hadoopIcon from './../../img/hadoop.png';
import kafkaIcon from './../../img/kafka.png';
import elasticsearchIcon from './../../img/elasticsearch.png';
import grefanaIcon from './../../img/grefana.png';
import promethiusIcon from './../../img/promethius.png';


import nxpIcon from './../../img/nxp.png';
import mysqlIcon from './../../img/mysql.png';
import cassendraIcon from './../../img/cassendra.png';
import scyllaIcon from './../../img/scylla.png';
import mongoIcon from './../../img/mongo.png';

import vimIcon from './../../img/vim.png';
import atomIcon from './../../img/atom.png';
import visualstudioIcon from './../../img/visualstudio.png';
import sublimeIcon from './../../img/sublime.png';

import stIcon from './../../img/st.png';
import microchipIcon from './../../img/microchip.png';
import texasIcon from './../../img/texas.png';

import axios from "axios";
import KnowledgeUnit from './KnowledgeUnit';

const cardColor ="#FDEBD0";


class KnowledgeDetails extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{ width:'100%'}}>
                <div style={{ display: 'flex', flexDirection:"column", justifyContent: "space-between", alignItems:"center", padding: "25px",  background:'#FDEBD0'}}>
                        <div style={{fontFamily: "serif", fontSize:"100%",fontWeight:"bold" }}><h5>Languages</h5></div>
                        <div style={{ display:"flex", justifyContent: "space-evenly", width:"100%", marginBottom:"10px"}}>
                           <KnowledgeUnit icon ={cppIcon} name = "C++" />
                           <KnowledgeUnit icon ={javaIcon} name = "Java" />
                           <KnowledgeUnit icon ={pythonIcon} name = "Python" />
                        </div>
                </div>
                
                <div style={{ display: 'flex', flexDirection:"column", justifyContent: "space-between", alignItems:"center", padding: "25px", marginTop:"5px", background:'#FDEBD0'}}>
                        <div style={{fontFamily: "serif", fontSize:"100%",fontWeight:"bold" }}><h5>Additional Languages</h5></div>
                        <div style={{ display:'flex',display:"flow", justifyContent: "space-evenly", alignItems:"center", width:"100%"}}>
                           <KnowledgeUnit icon ={shellIcon} name = "Shell" />
                           <KnowledgeUnit icon ={perlIcon} name = "Perl" />
                           <KnowledgeUnit icon ={awkIcon} name = "AWK" />
                           <KnowledgeUnit icon ={assemblyIcon} name = "Assembly" />
                           <KnowledgeUnit icon ={jsIcon} name = "Java-Script" />
                           <KnowledgeUnit icon ={reactjsIcon} name = "React-Js" />
                           <KnowledgeUnit icon ={scalaIcon} name = "Scala" />
                        </div>
                </div>

                <div style={{ display: 'flex', flexDirection:"column", justifyContent: "space-between", alignItems:"center", padding: "25px", marginTop:"5px", background:'#FDEBD0'}}>
                        <div style={{fontFamily: "serif", fontSize:"100%",fontWeight:"bold" }}><h5>Frameworks/Services</h5></div>
                        <div style={{ display:'flex', display:"flow", justifyContent:"space-evenly", alignItems:"center"}}>
                           <KnowledgeUnit icon ={springIcon} name = "SpringBoot" />
                           <KnowledgeUnit icon ={hibernateIcon} name = "Hibernate" />
                           <KnowledgeUnit icon ={zookeeperIcon} name = "ZooKeeper" />
                           <KnowledgeUnit icon ={mqttIcon} name = "MQTT" />
                           <KnowledgeUnit icon ={flinkIcon} name = "Flink" />
                           <KnowledgeUnit icon ={hadoopIcon} name = "Hadoop" />
                           <KnowledgeUnit icon ={kafkaIcon} name = "Kafka" />
                           <KnowledgeUnit icon ={elasticsearchIcon} name = "Elastic-Search" />
                           <KnowledgeUnit icon ={grefanaIcon} name = "Grefana" />
                           <KnowledgeUnit icon ={promethiusIcon} name = "Promethius" />
                        </div>
                </div>

                <div style={{ display: 'flex', flexDirection:"column", justifyContent: "space-between", alignItems:"center", padding: "25px", marginTop:"5px", background:'#FDEBD0'}}>
                        <div style={{fontFamily: "serif", fontSize:"100%", fontWeight:"bold" }}><h5>Databases</h5></div>
                        <div style={{ display:'flex',display:"flow", justifyContent:"space-evenly", width:"100%"}}>
                           <KnowledgeUnit icon ={mysqlIcon} name = "Mysql" />
                           <KnowledgeUnit icon ={cassendraIcon} name = "Cassendra" />
                           <KnowledgeUnit icon ={scyllaIcon} name = "Scylla" />
                           <KnowledgeUnit icon ={mongoIcon} name = "Mongo" />
                        </div>
                </div>

                <div style={{ display: 'flex',flexDirection:"column",  justifyContent: "space-between", alignItems:"center", padding: "25px", marginTop:"5px", background:'#FDEBD0'}}>
                        <div style={{fontFamily: "serif", fontSize:"100%",fontWeight:"bold" }}><h5>Editors</h5></div>
                        <div style={{ display:'flex', display:"flow",justifyContent:"flex-end", width:"100%"}}>
                           <KnowledgeUnit icon ={vimIcon} name = "Vim" />
                           <KnowledgeUnit icon ={atomIcon} name = "Atom" />
                           <KnowledgeUnit icon ={visualstudioIcon} name = "Visual-Studio" />
                           <KnowledgeUnit icon ={sublimeIcon} name = "Sublime" />
                        </div>
                </div>

                <div style={{ display: 'flex',flexDirection:"column",  justifyContent: "space-between", alignItems:"center", padding: "25px", marginTop:"5px", background:'#FDEBD0'}}>
                        <div style={{fontFamily: "serif", fontSize:"100%",fontWeight:"bold" }}><h5>Micro-controllers</h5></div>
                        <div style={{ display:'flex',justifyContent: "space-evenly", width:"100%",marginBottom:"10px"}}>
                           <KnowledgeUnit icon ={stIcon} name = "ST" />
                           <KnowledgeUnit icon ={nxpIcon} name = "NXP" />
                           <KnowledgeUnit icon ={microchipIcon} name = "Microchip" />
                           <KnowledgeUnit icon ={texasIcon} name = "Texas" />
                           
                        </div>
                </div>
            </div>       
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(KnowledgeDetails);