import React, { Component } from "react";

class HiTekIEBCUDesc extends Component {
  state = {
    text: `Designed the entire architecture and programmed the kernel of IEBCU, IEBCU is used in industry for protect the other electrical equipments & devices. We developed this project by using Pic 16F883 which is very low in cost, which is based on 16 bit microcontroller.    `
  };

  render() {
    return this.state.text;
  }
}

export default HiTekIEBCUDesc;