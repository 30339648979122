import React, { Component } from "react";

class AindraDesc extends Component {
  state = {
    text: "Part of research and development team, responsible micro-services ( Back- end) development"
  };

  render() {
    return this.state.text;
  }
}

export default AindraDesc;