import React, { Component } from "react";

class HiTekPFCDesc extends Component {
  state = {
    text: `Programmed the core of PFC System and build interface using LCD-2 line display, PFC is used in indutry to correct the power factor by balancing lagging or leading of voltage with respect to current, this device is very effective to save the cost of electricity where inductive or capacity load are more. `
  };

  render() {
    return this.state.text;
  }
}

export default HiTekPFCDesc;