
import React, { Component } from "react";

class EicherRGBLVDSTitle extends Component {
  state = {
    text: `RGB-LVDS Display : Integration`
  };

  render() {
    return this.state.text;
  }
}

export default EicherRGBLVDSTitle;