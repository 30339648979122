
import React, {Component} from 'react';



class Details extends  Component{

    state = {
        text: `Designed project which detect the abnormal behaviour of human’s activity in night time and if found it turn on siren. ( openCV, Assembly, PIC 16F72 )`
      };
    
      render() {
        return this.state.text;
      }
      
}


export default Details;










