import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../actions/Actions";
import {connect} from "react-redux";
import iitkgp from './../../img/iitkgp.png';
import ieee from './../../img/ieee.png';
import jnv from './../../img/jnv.png';
import KnowledgeUnit from "./KnowledgeUnit";

import stIcon from './../../img/st.png';
import microchipIcon from './../../img/microchip.png';
import texasIcon from './../../img/texas.png';

import fight4eduIcon from './../../img/fight4edu.png';
import sfpIcon from './../../img/sfp.png';
import nxpIcon from './../../img/nxp.png';
import aazadbharatIcon from './../../img/logo.png';

import axios from "axios";



class ConsultUs extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{ width:'100%', background:'#FDEBD0'}}>
               <div style={{ display:'flex', justifyContent:"center", paddingTop:"10px"}}>
                    <div style={{ display: 'flex',flexDirection:"column",  justifyContent: "space-between", alignItems:"center", padding: "25px"}}>
                        <div style={{fontFamily: "serif", fontSize:"70%",fontWeight:"bold" }}><p>Micro-controllers</p></div>
                        <div style={{ display:'flex',justifyContent: "space-evenly", width:"100%",marginBottom:"10px"}}>
                           <KnowledgeUnit icon ={stIcon} name = "ST" />
                           <KnowledgeUnit icon ={nxpIcon} name = "NXP" />
                           <KnowledgeUnit icon ={microchipIcon} name = "Microchip" />
                           <KnowledgeUnit icon ={texasIcon} name = "Texas" />
                        </div>
                </div>
               </div>
              
               <div style={{ display:'flex',alignItems:"center", justifyContent:"center",marginTop:"20px", marginBottom:"20px",fontFamily: "serif", fontSize:"100%" }}>
                    <div style={{ width:"90%",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        <img src={sfpIcon} style={{width:"100px", height:"100px"}} />
                           
                           <div style={{marginTop: "10px", fontSize:"70%"}}>
                               <p>Sure For Pure Pvt Ltd</p>
                           </div>
                           <div style={{margin: "-16px", fontSize:"70%"}}>
                               <p>office@sureforpure.com</p>
                           </div>
                           <div style={{margin: "-0px", fontSize:"70%"}}>
                               <p>sureforpure.com</p>
                           </div>
                    </div>
               </div>
               <div style={{ display:'flex', marginTop:"5px", justifyContent:"center"  }}>
                    <div style={{width:'50%', height:'2px', background: 'black'}}></div>
               </div>
               <div style={{ display:'flex',alignItems:"center", justifyContent:"center",fontFamily: "serif", fontSize:"100%" }}>
                    <div style={{ width:"60%",display:"flex", flexDirection:"column", justifyContent:"center", textAlign:"center", alignItems:"center"}}>
                           <div style={{margin:"10px"}}>
                               <p style={{fontSize:"70%"}}>Empowerment your business with us, with reliable solftware solutions</p>
                           </div>
                    </div>
               </div>
               <div style={{ display:'flex', justifyContent:"center", marginTop:"-10px" }}>
                    <div style={{width:'50%', height:'2px', background: 'black'}}></div>
               </div>
               <div style={{ marginTop:12, display:'flex', marginTop: "20px", justifyContent:"center"  }}>
                    <div style={{ width:"80%",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", fontFamily: "serif", fontSize:"100%", fontStyle:"bold"}}>
                        <p style={{fontSize:"70%", fontStyle:"italic", textAlign:"justify"}}>
                            If you are entrepreneur, innovator, industry revolutionary and want to make a unique products that impact the mordern 
                            society and make you stand out of crowd then consult with me, I will provide a technical solution to you and your company.
                            As I have very vast experiences in different technologies with world top most companies, I know how to design products from
                            scratch that will be sustain in any condtion. Please drop a mail/sms on given email/phone.
                        </p>
                       
                    </div>
                   
               </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(ConsultUs);