import React, { Component } from "react";

class OlaPos extends Component {
  state = {
    text: "Lead Software Engineer"
  };

  render() {
    return this.state.text;
  }
}

export default OlaPos;