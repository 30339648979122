import React, { Component } from "react";

class EicherReOSDesc extends Component {
  state = {
    text: `ReOS refers to the Royal Enfield Operating System, which was written and developed especially for the Electric Vehicle by customizing the Android Open Source Projects (AOSP) to make it better to interact with the hardwares of the vehicle and display the required parameters and functionality of the vehicle to the user. 

This ReOS used to bring the vehicle details to customers in an interactive way. 
`
  };

  render() {
    return this.state.text;
  }
}

export default EicherReOSDesc;