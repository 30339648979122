import React, { Component } from "react";

class OlaProfileDesc extends Component {
  state = {
    text: `We are providing, profile based features in electric scooter that lead to role based access of features. This feature helps vehicle's owner to provide limited access and set parental control on vehicle. Users can set the areal-boundary, time limit, speed limit etc so that secondary users ( Childs/Partner/Friends/etc ) do't misused the vehicle, do't cross the certain limit during uses of electic vehicle.    `
  };

  render() {
    return this.state.text;
  }
}

export default OlaProfileDesc;