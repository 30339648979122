import React, { Component } from "react";

class HiTekIEBCUTitle extends Component {
  state = {
    text: `IEBCU: Intelligent Extension board Control Unit`
  };

  render() {
    return this.state.text;
  }
}

export default HiTekIEBCUTitle;