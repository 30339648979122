import React, { Component } from "react";

class EicherHMIClusterTitle extends Component {
  state = {
    text: `The boot up time represents the time taken from turning the display ON to the time when the main home screen image is displayed on the display. Right now, the display used, either RGB or LVDS has a boot up time of approximately 40 seconds. The aim is to bring down the boot up time to approximately 30 seconds. To achieve this aim, many functions that were added just for the testing purpose had to be removed from the files of Android Operating System.

The boot up time mainly depends on efficiently using the input/output parameters. Optimizing the AOSP such that the startup won’t have to pass through many processes before reaching the working state has to be done. Optimization includes removing any unused code snippets from the code, efficiently updating the code, aiming for the same output with fewer lines of code, remove abl fct mode, removing the unused audio devices, disabling some camera services, and removing the unused kernel configurations is done in order to solve the concerned issue.

`
  };

  render() {
    return this.state.text;
  }
}

export default EicherHMIClusterTitle;