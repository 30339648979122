import React, { Component } from "react";

class EicherHMIClusterTitle extends Component {
  state = {
    text: `Telemetry automatically collects the data and processes from remote sources. Telemetry data helps in  monitoring security, application health, quality and performance.

This telemetry collects the data from the SOM module which is basically the vehicle CAN data and publishes it on the cloud.
`
  };

  render() {
    return this.state.text;
  }
}

export default EicherHMIClusterTitle;