
import React, {Component} from 'react';



class Details extends  Component{

    state = {
        text: `Analysing the power factor of system in real time by measuring different angle between current and voltage & applying the appropriate the permutation and combination of capacitance & inductannce,
        using embeded system programming with uC-16F72 making power factor of hole system as unity.`
      };
    
      render() {
        return this.state.text;
      }
      
}


export default Details;










