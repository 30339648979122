
import React, {Component} from 'react';

class Titles extends  Component{

    state = {
        text: `Title: Theif Detector`
      };
    
      render() {
        return this.state.text;
      }
      
}

//Work at: Hi-Tek Electronics

export default Titles;

