import React, { Component } from "react";

class EicherHMIClusterDesc extends Component {
  state = {
    text: `Telemetric-Cloud Pipe`
  };

  render() {
    return this.state.text;
  }
}

export default EicherHMIClusterDesc;