import React, { Component } from "react";

class EicherHMIClusterDesc extends Component {
  state = {
    text: `30s Boot-Up: Fasted booting system in 2W worlds`
  };

  render() {
    return this.state.text;
  }
}

export default EicherHMIClusterDesc;