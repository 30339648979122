import React, {Component} from "react";
import {connect} from "react-redux";
import { CENTRAL_CONTENT, USER_INFO, EXPERIENCE_DETAILS, INDUSTRIAL_DETAILS,KNOWLEDGE_DETAILS} from "../../constants/Constants";
import Navbar from "../navbar/Navbar";
import {updateCentralContent} from "../../../desk/actions/Actions";
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Description from "../Description";
import KnowledgeDetails from "../KnowledgeDetails";
import ExperienceDetails from "../ExperienceDetails";
import IndustrialProjects from "../IndustrialProjects";
import InitiationDetails from "../InitiationDetails";
import ConsultUs from "../ConsultUs";




class Home extends  Component{

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.props.updateCentralContent(( cookies.get('f4e_auth') != null ) ? USER_INFO: CENTRAL_CONTENT);
    }

    render() {
        return (
            <div>
                <div>
                    <Header/>
                </div>
                <div>
                    <Navbar style={{zIndex:10000}}></Navbar>
                </div>
                
                <div>
    
                    { this.props.centralContent === "CENTRAL_CONTENT" && <Description/> }
                    { this.props.centralContent === "INITIATION_DETAILS" &&    <InitiationDetails/> }
                    { this.props.centralContent === "KNOWLEDGE_DETAILS" && <KnowledgeDetails/>}
                    { this.props.centralContent === "EXPERIENCE_DETAILS" && <ExperienceDetails/>}
                    { this.props.centralContent === "INDUSTRIAL_DETAILS" && <IndustrialProjects/>}
                    { this.props.centralContent === "CONSULT_DETAILS" && <ConsultUs/>}
            
                </div>
                <div>
                    <Footer/>
                </div>
               
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent
})

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Home));