import React, { Component } from "react";

class OlaDataPipelineTitle extends Component {
  state = {
    text: `Data-Pipeline: Cloud to vehicle & vehicle to cloud communication
    `
  };

  render() {
    return this.state.text;
  }
}

export default OlaDataPipelineTitle;