
import React, { Component } from "react";

class EicherRGBLVDSDesc extends Component {
  state = {
    text: `RGB Display unit is 720 X 720 pixel circular display unit having depth of each pixel is 8 bits. RGB TFT display’s ckt board has deserializer which is connected with coaxial cable from VCU boards having serializer to avoid EMI and getting signal transmitted at longer distance without any disturbance. SOM has DSI connection as well as I2C connection with Serializer. DSI connection is for sending the signal to Serializer and I2C connection is for setting or updating the proper values. As we are using the MAX96755F as Serializer & MAX9276B as Deserializer, Analog devices have given the set of registers and corresponding values that is suitable for RGB display. 

The most important point for bringing up the display was establishing the i2c connection to the serializer. Writing the i2c registers with the correct pattern and flow will set up the serializer as required. The android ADB console comes with some i2c utilities that help to test various parameters concerning the i2c line. Using these functions, various experiments were taken place in order to confirm whether the registers are read and written as per the requirement or not. Once the pattern of read and write is perfect, the serializer will start sending accurate data to the deserializer. 

For color distortion issues, the byte correction has to be done on the serializer side in order to send the exact data to the deserializer. Serialzier register addresses are 10 bit addresses when we are giving the 7 or 8 bits address, the serializer will not pass the correct data which results in color distortion in the RGB Display screen. 

Same steps as RGB were taken in order to bring up the LVDS display. LVDS stands for low voltage differential signaling. The dimensions of the display is 800 x 800. So we need to implement the same size touch screen to perform the operations in the OS. Also the display appears in a rounded screen with a radius of 400 pixels.

The main difference occurred in the i2c registers on the deserializer side. Since the deserializer used in RGB is different from that of LVDS, the i2c configuration registers are also different. The deserializer IC used in LVDS is MAX96752R. 

After carrying out the same tests as of RGB display, the bring up of LVDS display was done.
`
  };

  render() {
    return this.state.text;
  }
}

export default EicherRGBLVDSDesc;