import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../actions/Actions";
import {connect} from "react-redux";
import iitkgp from './../../img/iitkgp.png';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";

import olaelectricIcon from './../../img/olaelectric.png';
import makemytripIcon from './../../img/makemytrip.png';
import hitekIcon from './../../img/hitek.png';
import amazonIcon from './../../img/amazon.png';
import aindraIcon from './../../img/aindra.png';

import Amazon from './../components/exp/Amazon';
import AindraSystem from './../components/exp/AindraSystem';
import HitekIndia from './../components/exp/HitekIndia';
import MakeMyTrip from './../components/exp/MakeMyTrip';
import OlaElectric from './../components/exp/OlaElectric';
import Experience from './../components/exp/Experience';
import Eicher from './../components/exp/Eicher';
import HitekIndiaI from './exp/HitekIndiaI';


class ExperienceDetails extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{ width:'100%', background:'white'}}>
                <Experience/>
                <Eicher/>
                <OlaElectric/>
                <MakeMyTrip/>
                <HitekIndia/>
                <Amazon/>
                <AindraSystem/>
                <HitekIndiaI/>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(ExperienceDetails);