import React, { Component } from "react";

class HiTekTrackingHiLineDesc extends Component {
  state = {
    text: `Developing backend services which actually monitor the Hi-Line devices online and update status and devices’s information on portal so that technicians & engineers can able to get real situation of devices. We developed this service using Java, Spring, Kafka, File System, mysql, We use file system for appending the device’s information with timestamp and mysql is used to store the current device’s status and information.`
  };

  render() {
    return this.state.text;
  }
}

export default HiTekTrackingHiLineDesc;