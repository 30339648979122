import React, { Component } from "react";

class MMTDesc extends Component {
  state = {
    text: `Part of Technology Development department, supervise and managing team, designing & developing projects.  `
  };

  render() {
    return this.state.text;
  }
}

export default MMTDesc;