import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../../actions/Actions";
import {connect} from "react-redux";

import axios from "axios";


class Footer extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{ width:'100%', background:'DarkSlateGray'}}>
                    <div style={{display: "flex", displayContent: "space-around"}}>
                        <div style={{ width:"100%"}}>
                                <div style={{display: "flex", justifyContent: "space-evenly" }}>
                                    <div style={{display: "flex", flexDirection:"column", justifyContent:"left", margin:"25px"}} >
                                        <p  style={{margin: "0px", padding:"0px", color:"white",fontFamily: "Sans-serif", fontSize:"70%", fontStyle:"italic"}}>
                                            Permanent Address: 
                                        </p> 
                                        <div style={{margin: "0px", padding:"0px", color:"white",fontFamily: "Sans-serif", fontSize:"70%", fontStyle:"italic"}}>
                                            Sarai, Kanakpur <br/>
                                            Pindra, Varanasi <br/>
                                            U.P, India-221206
                                        </div>
                                    </div>
                                    <div style={{ height:"70px", marginTop: "25px", width:"3px", background:"red"}}></div>
                                    <div style={{display: "flex", flexDirection:"column", justifyContent:"left", margin:"25px"}} >
                                        <p  style={{margin: "0px", padding:"0px", color:"white",fontFamily: "Sans-serif", fontSize:"70%", fontStyle:"italic"}}>
                                            Present Address: 
                                        </p>
                                        <div style={{margin: "0px", padding:"0px", color:"white",fontFamily: "Sans-serif", fontSize:"70%", fontStyle:"italic"}}>
                                            #24165, DLF Gardencity <br/>
                                            Semmancheri, Chennai <br/>
                                            Tamilnadu, India-600097
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

            </div>

        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(Footer);