import React, { Component } from "react";

class OlaOTPServiceTitle extends Component {
  state = {
    text: `Soteria Service: OTP System`
  };

  render() {
    return this.state.text;
  }
}

export default OlaOTPServiceTitle;