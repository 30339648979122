import React, { Component } from "react";

class HiTekWorkYear extends Component {
  state = {
    text: `Oct 2015 to Feb 2018`
  };

  render() {
    return this.state.text;
  }
}

export default HiTekWorkYear;