
import React, {Component} from 'react';



class Details extends  Component{

    state = {
        text: `Designed hardware and software in C++ for solar control unit which is very low in const, that operate on 12-24V with highly eﬀicient micro-controller e.i., PIC 16F72.`
      };
    
      render() {
        return this.state.text;
      }
      
}


export default Details;










