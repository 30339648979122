import React, { Component } from "react";

class AmazonFBADesc extends Component {
  state = {
    text: ` Worked on FBA-Subscribe and Save module. Amazon uses this service to deliver the products monthly, 
    quarterly or annually etc., based on requirement of users, once user will subscribe and save the particular item. 
    Learned the amazons eco-system, how to do efficient/correct programming, different platform and communication. 
    As I was part of amazon's world biggest family, I learned clear vision, clear communication, conceptual/logical 
     deep thought and hard work is everything nothing else. `
  };

  render() {
    return this.state.text;
  }
}

export default AmazonFBADesc;