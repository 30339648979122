
import React, {Component} from 'react';



class Details extends  Component{

    state = {
        text: `By using C/C++ programming generated square wave with varing active time w.r.t to time with PWM module on chip 16f72, able to generate Sine-Wave when passing singal to filter.
               This feature what we have developed, was being used in Interver machine.
        `
      };
    
      render() {
        return this.state.text;
      }
      
}


export default Details;










