import React, {Component} from 'react';
import {updateCentralContent, updateStdClass}  from "../../actions/Actions";
import {connect} from "react-redux";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";


import fight4eduIcon from './../../../img/fight4edu.png';
import sfpIcon from './../../../img/sfp.png';
import aazadbharatIcon from './../../../img/logo.png';
import amazonIcon from './../../../img/amazon.png';
import hitekIcon from './../../../img/hitek.png';
import TrackingHiLine from './hitek/TrackingHiLine';
import IEBCU from './hitek/IEBCU';
import PFC from './hitek/PFC';
import PositionWorkYears from './hitek/PositionWorkYears';
import Description from './hitek/Description';



class HitekIndiaI extends  Component{

    constructor(props) {
        super(props);
        this.state = {
            togglingDetail:false,
            isLoading: false,
            signupInfo:{}
        };
    }


    componentDidMount() {
        /*
        axios.get(ApiUrl.GET_COURSE).then(response=> {
            if (response.status === 200) {
            
                this.setState({ isLoading: false });
            }else { 
               
                throw new Error("Bad response from server"); }
        }).catch((err)=>{
            this.setState({signupInfo: {errorStatus:true, msg:err.response.data.message}});
            setTimeout(() => {
                this.setState({signupInfo: {errorStatus:false, msg:err.response.data.message}});
            }, 4000)
        });
        */

    }

   

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        return (
        
            <div style={{ display: 'flex', justifyContent: "flex-start", alignItems:"top", background:'white', marginTop: "5px", background:"#5D6D7E"}}>
                        
                <PositionWorkYears/>

                <div style={{width:"80%",background:"#FDEBD0", padding: "15px"}} >
                    <Description/>
                    
                    <TrackingHiLine/>    
                    <div style={{height:"2px", width: "90%", background:"white"}}></div>
                    <IEBCU/>  
                    <div style={{height:"2px", width: "90%", background:"white"}}></div>
                    <PFC/>  
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>({
    updateCentralContent:data=>dispatch(updateCentralContent(data)),
    updateStdClass:data=>dispatch(updateStdClass(data))
})

const mapStateToProps=state=>({
    centralContent: state.deskReducer.centralContent,
    stdClass: state.deskReducer.updateStdClass
})

export default connect(mapStateToProps,mapDispatchToProps)(HitekIndiaI);