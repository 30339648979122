
import React, {Component} from 'react';

class Title extends  Component{

    state = {
        text: `Title: Smart Grid`
      };
    
      render() {
        return this.state.text;
      }
      
}

//Work at: Hi-Tek Electronics

export default Title;

