import React, { Component } from "react";

class EicherHMIClusterDesc extends Component {
  state = {
    text: `Data aggregation is the main part of ReOS while we are bringing the data from hardwares by using UART. Through this method, we are displaying the data in our cluster and sending it to the cloud applications.

This Data aggregation is happening in every frequency of time declared as per the assets folder. Here the data will be received by 10 ms, 50 ms and 500 ms of frequency to the ReOS.

Currently we are running the service in the background of ReOS for collecting and sending the data in a frequent time to the cloud and other applications.
`
  };

  render() {
    return this.state.text;
  }
}

export default EicherHMIClusterDesc;